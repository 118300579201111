import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Navbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faStore, faHeadphones, faEnvelope, faShoppingCart, faBars } from '@fortawesome/free-solid-svg-icons';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const closeDropdown = () => {
      setIsOpen(false);
    };

    // Close the dropdown when clicking outside of it or on window resize
    document.addEventListener('click', closeDropdown);
    window.addEventListener('resize', closeDropdown);

    return () => {
      document.removeEventListener('click', closeDropdown);
      window.removeEventListener('resize', closeDropdown);
    };
  }, []);

  const toggleDropdown = (event) => {
    event.stopPropagation();
    setIsOpen(!isOpen);
  };

  return (
    <nav className={`navbar ${isOpen ? 'active' : ''}`}>
      <div className="logo">Monika Construction Equipment</div>
      <FontAwesomeIcon icon={faBars} className="dropdown-icon" onClick={toggleDropdown} />
      <ul className={`nav-links ${isOpen ? 'active' : ''}`}>
        <li><Link to="/"><FontAwesomeIcon icon={faHome} /> Home</Link></li>
        <li><Link to="/store"><FontAwesomeIcon icon={faStore} />Store</Link></li>
        <li><Link to="/contact"><FontAwesomeIcon icon={faEnvelope} /> Contact</Link></li>
      </ul>
    </nav>
  );
}

export default Navbar;

const beatsData = [
  {
    id: 'beat1',
    categoryName: '',
    categoryId:1,
    title: 'CONCRETE ROLLER SCREED PAVER MACHINE ',
    imageUrl: require('../assets/demo/1.jpg'),
    price: 20,
    description: `<table>
    <tr>
        <th>MODEL</th>
        <th>MONIKA 219</th>
    </tr>
    <tr>
        <td>WIDTH</td>
        <td>3mtr/5mtr/7mtr</td>
    </tr>
    <tr>
        <td>DIAMETER OF ROLLER</td>
        <td>219mm</td>
    </tr>
    <tr>
        <td>NO. OF NEEDLE VIBRATOR</td>
        <td>6/8/10</td>
    </tr>
    <tr>
        <td>QUANTITY OF ROLLER</td>
        <td>2Pcs</td>
    </tr>
    <tr>
        <td>QUANTITY OF AUGER</td>
        <td>1Pcs</td>
    </tr>
    <tr>
        <td>VIBRATIONN/LIFTING MOTOR</td>
        <td>1.5KW</td>
    </tr>
    <tr>
        <td>VIBRATORS DETAIL</td>
        <td>50mm,2mtr</td>
    </tr>
    <tr>
        <td>TRAVELING MOTOR</td>
        <td>3Kw</td>
    </tr>
    <tr>
        <td>AUGER MOTOR</td>
        <td>4Kw</td>
    </tr>
    <tr>
        <td>TRAVEL SPEED</td>
        <td>0-3m/min</td>
    </tr>
    <tr>
        <td>MAX PAVING THICKNESS</td>
        <td>300Mm</td>
    </tr>
    <tr>
        <td>WEIGHT</td>
        <td>1200Kg</td>
    </tr>
</table>
`,
  },
  {
    id: 'beat2',
    categoryName: '',
    categoryId:1,
    title: 'DOUBLE BEAM SCREED VIBATOR',
    imageUrl: require('../assets/demo/2.jpg'),
    price: 25,
    description: `<table>
    <tr>
        <th>MODEL</th>
        <th>MONIKA</th>
    </tr>
    <tr>
        <td>WIDTH</td>
        <td>4.25mtr</td>
    </tr>
    <tr>
        <td>DIAMETER OF ROLLER</td>
        <td>219mm</td>
    </tr>
    <tr>
        <td>TRAVEL SPEED</td>
        <td>3-9m/min</td>
    </tr>
    <tr>
        <td>QUANTITY OF ROLLER</td>
        <td>2Pcs</td>
    </tr>
    <tr>
        <td>MAX PAVING THICKNESS</td>
        <td>300Mm</td>
    </tr>
    <tr>
        <td>ENGINE</td>
        <td>GX390</td>
    </tr>
    <tr>
        <td>ENGINE TYPE</td>
        <td>AIR COOLED</td>
    </tr>
    <tr>
        <td>FUEL TANK</td>
        <td>3.6Ltr</td>
    </tr>
    <tr>
        <td>WEIGHT</td>
        <td>300-700Kg</td>
    </tr>
    <tr>
        <td>DIMENSIONS</td>
        <td>Lx900x800mm</td>
    </tr>
    </table>`
  },
  {
    id: 'beat3',
    categoryName: '',
    categoryId:1,
    title: 'STIRRUP BENDING MACHINE',
    imageUrl: require('../assets/demo/3.jpg'),
    price: 30,
    description: `<table>
    <tr>
        <th>MODEL</th>
        <th>GF20S</th>
        <th>GF20</th>
    </tr>
    <tr>
        <td>BENDING RANGE (TMT BAR)</td>
        <td>4-16mm</td>
        <td>4-16mm</td>
    </tr>
    <tr>
        <td>BENDING RANGE (ROUND BAR)</td>
        <td>4-20mm</td>
        <td>4-20mm</td>
    </tr>
    <tr>
        <td>PRESENT ANGLE</td>
        <td>90° / 135°</td>
        <td>90° / 135°</td>
    </tr>
    <tr>
        <td>WORKING SPEED</td>
        <td>25-30r/min</td>
        <td>25-30r/min</td>
    </tr>
    <tr>
        <td>ANGLE FEED</td>
        <td>MANUAL</td>
        <td>MANUAL</td>
    </tr>
    <tr>
        <td>POWER</td>
        <td>2.2Kw</td>
        <td>2.2Kw</td>
    </tr>
    <tr>
        <td>VOLTAGE</td>
        <td>220V</td>
        <td>415V</td>
    </tr>
    <tr>
        <td>WEIGHT</td>
        <td>90</td>
        <td>95</td>
    </tr>
    <tr>
        <td>DIMENSION</td>
        <td>800x550x850</td>
        <td>800x550x850</td>
    </tr>
</table>
`
  },
  {
    id: 'beat4',
    categoryName: '',
    categoryId:1,
    title: 'STIRRUP BENDING MACHINE',
    imageUrl: require('../assets/demo/4.jpg'),
    price: 18,
    description: `<table>
    <tr><th>MODEL</th><th>GW20S</th><th>GW20D</th></tr>
    <tr><td>BENDING RANGE (TMT BAR)</td><td>4-16mm</td><td>4-16mm</td></tr>
    <tr><td>BENDING RANGE (ROUND BAR)</td><td>4-20mm</td><td>4-20mm</td></tr>
    <tr><td>PRESENT ANGLE</td><td>90° / 135°</td><td>90° / 135°</td></tr>
    <tr><td>WORKING SPEED</td><td>25-30r/min</td><td>25-30r/min</td></tr>
    <tr><td>ANGLE FEED</td><td>AUTO</td><td>AUTO</td></tr>
    <tr><td>POWER</td><td>2.2Kw</td><td>2.2Kw</td></tr>
    <tr><td>VOLTAGE</td><td>220V</td><td>415V</td></tr>
    <tr><td>WEIGHT</td><td>90Kg</td><td>95kg</td></tr>
    <tr><td>DIMENSION</td><td>800x550x850</td><td>800x550x850</td></tr>
    </table>`,
  },
  {
    id: 'beat5',
    categoryName: '',
    categoryId:1,
    title: 'BAR DECOILING MACHINE',
    imageUrl: require('../assets/demo/5.jpg'),
    price: 15,
    description: `<table>
    <tr><th>MODEL</th><th>GT4-12</th></tr>
    <tr><td>STRAIGHTENING RANGE</td><td>REBAR 3-12mm</td></tr>
    <tr><td>POWER</td><td>9Kw</td></tr>
    <tr><td>Speed</td><td>40/min</td></tr>
    <tr><td>WEIGHT</td><td>320Kg</td></tr>
    <tr><td>Oiling</td><td>15-20ltr</td></tr>
    </table>`,
  },
  {
    id: 'beat6',
    categoryName: '',
    categoryId:1,
    title: 'BAR DECOILING MACHINE',
    imageUrl: require('../assets/demo/6.jpg'),
    price: 22,
    description: `<table>
    <tr><th>MODEL</th><th>GT4-14</th></tr>
    <tr><td>STRAIGHTENING RANGE</td><td>REBAR 4-12mm</td></tr>
    <tr><td>POWER</td><td>16Kw</td></tr>
    <tr><td>Speed</td><td>40/min</td></tr>
    <tr><td>WEIGHT</td><td>535Kg</td></tr>
    <tr><td>Oiling</td><td>20-25ltr</td></tr>
    </table>`,
  },
  {
    id: 'beat7',
    categoryName: '',
    categoryId:1,
    title: 'BAR BENDING MACHINE',
    imageUrl: require('../assets/demo/7.jpg'),
    price: 17,
    description: `<table>
    <tr><th>MODEL</th><th>GW42D</th><th>GW42C</th><th>GW40</th><th>GW42J</th></tr>
    <tr><td>Bending Range (TMT Bar)</td><td>32mm</td><td>32mm</td><td>32mm</td><td>32mm</td></tr>
    <tr><td>Bending Range (Round Bar)</td><td>40mm</td><td>40mm</td><td>40mm</td><td>40mm</td></tr>
    <tr><td>Working Plate</td><td>12mm</td><td>12mm</td><td>12mm</td><td>12mm</td></tr>
    <tr><td>Working Disc Dia</td><td>340mm</td><td>370mm</td><td>370mm</td><td>370mm</td></tr>
    <tr><td>Power</td><td>3Kw/5Hp</td><td>3Kw/5Hp</td><td>3Kw/5Hp</td><td>3Kw/5Hp</td></tr>
    <tr><td>Speed(rpm)</td><td>1440</td><td>1440</td><td>1440</td><td>1440</td></tr>
    <tr><td>Voltage</td><td>415V</td><td>415V</td><td>415V</td><td>415V</td></tr>
    <tr><td>Weight (kg)</td><td>275</td><td>300</td><td>320</td><td>320</td></tr>
    <tr><td>Dimension (mm)</td><td>850x740x830</td><td>880x800x850</td><td>850x800x840</td><td>880x770x850</td></tr>
    </table>`,
  },
  {
    id: 'beat8',
    categoryName: '',
    categoryId:1,
    title: 'BAR BENDING MACHINE',
    imageUrl: require('../assets/demo/8.jpg'),
    price: 28,
    description: `<table>
    <tr><th>MODEL</th><th>GW52C</th><th>GW50J</th><th>GW52J</th></tr>
    <tr><td>Bending Range (TMT Bar)</td><td>40mm</td><td>40mm</td><td>40mm</td></tr>
    <tr><td>Bending Range (Round Bar)</td><td>50mm</td><td>50mm</td><td>50mm</td></tr>
    <tr><td>Working Plate</td><td>12mm</td><td>12mm</td><td>12mm</td></tr>
    <tr><td>Working Disc Dia</td><td>400mm</td><td>400mm</td><td>400mm</td></tr>
    <tr><td>Power</td><td>4Kw/5.5HP</td><td>4Kw/5.5HP</td><td>4Kw/5.5HP</td></tr>
    <tr><td>Speed(rpm)</td><td>1440</td><td>1440</td><td>1440</td></tr>
    <tr><td>Voltage</td><td>415V</td><td>415V</td><td>415V</td></tr>
    <tr><td>Weight (kg)</td><td>370</td><td>400</td><td>400</td></tr>
    <tr><td>Dimension (mm)</td><td>1060x850x710</td><td>1060x850x710</td><td>970x770x880</td></tr>
    </table>`,
  },
  {
    id: 'beat9',
    categoryName: '',
    categoryId:1,
    title: 'BAR CUTTING MACHINE',
    imageUrl: require('../assets/demo/9.jpg'),
    price: 35,
    description: `<table>
    <tr><th>MODEL</th><th>GQ40H</th><th>GQ42</th></tr>
    <tr><td>Cutting Range(TMT)</td><td>32mm</td><td>32mm</td></tr>
    <tr><td>Cutting Range (Round)</td><td>32mm</td><td>32mm</td></tr>
    <tr><td>Cutting Time</td><td>2/Sec</td><td>2/Sec</td></tr>
    <tr><td>Power</td><td>3Kw/5HP</td><td>3Kw/5HP</td></tr>
    <tr><td>Speed(rpm)</td><td>2880</td><td>2880</td></tr>
    <tr><td>Voltage</td><td>415V</td><td>415V</td></tr>
    <tr><td>Weight</td><td>380Kg</td><td>380Kg</td></tr>
    <tr><td>Dimension(LxWxH)mm</td><td>1200x400x700</td><td>1370x490x780</td></tr>
    </table>`,
  },
  {
    id: 'beat10',
    categoryName: '',
    categoryId:1,
    title: 'BAR CUTTING MACHINE',
    imageUrl: require('../assets/demo/10.jpg'),
    price: 21,
    description: `<table>
    <tr><th>MODEL</th><th>GQ50E</th><th>GQ50</th></tr>
    <tr><td>Cutting Range(TMT)</td><td>36mm</td><td>40mm</td></tr>
    <tr><td>Cutting Range (Round)</td><td>50mm</td><td>50mm</td></tr>
    <tr><td>Cutting Time</td><td>2/Sec</td><td>2/Sec</td></tr>
    <tr><td>Power</td><td>4Kw/5.5HP</td><td>4Kw/5.5HP</td></tr>
    <tr><td>Speed(rpm)</td><td>2880</td><td>2880</td></tr>
    <tr><td>Voltage</td><td>415V</td><td>415V</td></tr>
    <tr><td>Weight</td><td>480Kg</td><td>515Kg</td></tr>
    <tr><td>Dimension(LxWxH)mm</td><td>1420x520x830</td><td>1410x530x850</td></tr>
    </table>`,
  },
  {
    id: 'beat11',
    categoryName: '',
    categoryId:1,
    title: 'AUTOMATIC STIRRUP BENDING MACHINE',
    imageUrl: require('../assets/demo/11.jpg'),
    price: 19,
    description: `<table>
    <tr><th>MODEL</th><th>GTD4-10S</th><th>GTD5-12S</th></tr>
    <tr><td>Single Wire Bending (mm)</td><td>4-10MS Bar</td><td>4-12Round Bar</td></tr>
    <tr><td>Double Wire Bending (mm)</td><td>4-8TMT Bar</td><td>4-8TMT Bar</td></tr>
    <tr><td>Max. Bending Angle</td><td>180°</td><td>180°</td></tr>
    <tr><td>Max. Traction Speed (m/min)</td><td>80</td><td>60</td></tr>
    <tr><td>Max. Bending Speed</td><td>800° /sec</td><td>800° /sec</td></tr>
    <tr><td>Length Tolerance (mm)</td><td>±1</td><td>±1</td></tr>
    <tr><td>Bending Angle Tolerance</td><td>±1</td><td>±1</td></tr>
    <tr><td>Average Power Consumption</td><td>4.5Kw</td><td>5Kw</td></tr>
    <tr><td>Rated Total Power (Kw)</td><td>20kw</td><td>22.7Kw (Include Compressor)</td></tr>
    <tr><td>Weight</td><td>1300Kg</td><td>1500Kg</td></tr>
    <tr><td>Productivity</td><td>2000/Hr</td><td>2000/Hr</td></tr>
    <tr><td>Dimension (mm)</td><td>2600x880x1700</td><td>3500x1000x2150</td></tr>
    </table>`,
  },
  {
    id: 'beat12',
    categoryName: '',
    categoryId:1,
    title: 'VIBRATING ROLLER',
    imageUrl: require('../assets/demo/12.jpg'),
    price: 24,
    description: `<table>
    <tr><th>MODEL</th><th>FVR600S</th><th>FVR600</th><th>FVR850</th></tr>
    <tr><td>Centrifugal force</td><td>15kn</td><td>30kn</td><td>30kn</td></tr>
    <tr><td>Starting Way</td><td>Manual</td><td>Electric</td><td>Electric</td></tr>
    <tr><td>Drive Model</td><td>Hydraulic</td><td>Hydraulic</td><td>Hydraulic</td></tr>
    <tr><td>Water Tank</td><td>30ltr</td><td>35ltr</td><td>35ltr</td></tr>
    <tr><td>Working Model</td><td>Forward & Backward</td><td>Forward & Backward</td><td>Forward & Backward</td></tr>
    <tr><td>Working Speed</td><td>0-4Km/Hr</td><td>0-4Km/Hr</td><td>0-5Km/Hr</td></tr>
    <tr><td>Engine</td><td>5.5Hp Greaves/Honda</td><td>9Hp Greaves</td><td>9Hp Greaves</td></tr>
    <tr><td>Vibration</td><td>70Hz</td><td>70Hz</td><td>70Hz</td></tr>
    <tr><td>Frequency Vibrating Clutch Method</td><td>Automatic</td><td>Automatic</td><td>Electrum</td></tr>
    <tr><td>Fuel Tank</td><td>2.5ltr</td><td>3.6ltr</td><td>3.6ltr</td></tr>
    <tr><td>Wheel Size (mm)</td><td>430x600</td><td>430x600</td><td>580x700</td></tr>
    <tr><td>Weight (Kg)</td><td>280</td><td>550</td><td>800</td></tr>
    <tr><td>Grade Ability</td><td>25%</td><td>30%</td><td>30%</td></tr>
    <tr><td>Package Size (mm)</td><td>1380x920x1100</td><td>1370x980x1200</td><td>2100x1000x1400</td></tr>
    </table>`,
  },
  
];

export default beatsData;

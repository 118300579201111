import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faYoutube, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import '../styles/Contact.css';

function Contact() {
  return (
    <section className="contact-section">
      <div className="contact-container">
        <div className="contact-details">
          <h2>Contact Details</h2>
          <div className="contact-info">
            <div className="contact-item">
              <FontAwesomeIcon icon={faEnvelope} className="contact-icon" />
              <p>mm8254133@gmail.com</p>
            </div>
            <div className="contact-item">
              <FontAwesomeIcon icon={faPhone} className="contact-icon" />
              <p>+918433485100</p>
            </div>
            <div className="social-icons">
              <a href="https://wa.me/918433485100">
                <FontAwesomeIcon icon={faWhatsapp} className="social-icon" />
              </a>
            </div>
          </div>
          <br></br>
          <p>
            <strong>Address:</strong> Vandana Enclave Khora Colony, Ghaziabad (U.P)-201309
          </p>
        </div>
        <div className="contact-form">
          <h2>Contact Us</h2>
          <br></br>
          <form>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input type="text" id="name" name="name" required />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input type="email" id="email" name="email" required />
            </div>
            <div className="form-group">
              <label htmlFor="mobile">Mobile Number</label>
              <input type="number" id="mobile" name="mobile" required />
            </div>
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    </section>
  );
}

export default Contact;

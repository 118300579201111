import React from 'react';
import '../styles/Cart.css';

function Cart() {
  // Fetch cart data and calculate total

  return (
    <div className="cart">
      <h2 className="cart-title">Shopping Cart</h2>
      {/* Render cart items */}
      <div className="cart-items">
        {/* Render individual cart items */}
      </div>
      <div className="cart-total">
        Total: ${/* Render total amount */}
      </div>
      <button className="checkout-button">Proceed to Checkout</button>
    </div>
  );
}

export default Cart;

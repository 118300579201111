import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './containers/Home';
import BeatStore from './containers/BeatStore';
import Cart from './components/Cart';
import Checkout from './containers/Checkout';
import './App.css';
import Footer from './components/Footer';
import IndividualBeat from './containers/IndividualBeat';
import Contact from './components/Contact';
import Mixing from './components/Mixing';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/store" element={<BeatStore />} />
          {/* <Route path="/category" element={<Category />} /> */}
          <Route path="/store/:beatId" element={<IndividualBeat />} />
          <Route path="/mixing" element={<Mixing />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer /> {/* Include the Footer component */}
      </div>
    </Router>
  );
}

export default App;

import React from 'react';
import beatMixingImage from '../assets/images/beat-mixing.png'; // Import the beat mixing image
import songMixingImage from '../assets/images/Music-Mixing.png'; // Import the song mixing image
import '../styles/Mixing.css'; // You can create this CSS file for styling
import MixingContactForm from './MixingContactForm';

function Mixing() {
  return (
    <>
    <div className="mixing-container">
      {/* Beat Mixing */}
      <div className="mixing-tile beat-mixing">
        <h2>Mixing - Beat</h2>
        <div className="mixing-image">
          <img src={beatMixingImage} alt="Beat Mixing" />
        </div>
        <div className="mixing-quality">
          <h3>QUALITY</h3>
          <ul>
            <li>Industry Quality Mixes</li>
            <li>Loud Punchy Tracks</li>
            <li>Huge Low End</li>
          </ul>
        </div>
        <div className="mixing-pricing">
          <h3>PRICING</h3>
          <p>1 Beat Mix - $40</p>
          <p>5 Beat Mixes - $175</p>
          <p>10 Beat Mixes - $370</p>
        </div>
      </div>

      {/* Song Mixing */}
      <div className="mixing-tile song-mixing">
        <h2>Mixing - Song</h2>
        <div className="mixing-image">
          <img src={songMixingImage} alt="Song Mixing" />
        </div>
        <div className="mixing-quality">
          <h3>QUALITY</h3>
          <ul>
            <li>Industry Quality Mixes</li>
            <li>Loud Punchy Tracks</li>
            <li>Clear Upfront Vocals</li>
          </ul>
        </div>
        <div className="mixing-pricing">
          <h3>PRICING</h3>
          <p>1 Song Mix - $150 (Without Vocal Edits)</p>
          <p>1 Song Mix - $200 (With Vocal Edits / Clean up & Autotune)</p>
        </div>
      </div>
      </div>
      <div className="contact-form-container">
        <MixingContactForm />
      </div>
      
      </>
  );
}

export default Mixing;

import React, { useState } from 'react';
import '../styles/BeatStore.css';
import BeatCard from '../components/BeatCard';
import beatsData from '../data/beatsData';

function BeatStore() {
  return (
    <div className="beat-store">
      <h2>Collections</h2>
      <div className="store-layout">
        <div className="category-list">
          <h2> Categories</h2>
            <button  className="category-button"> STIRRUP BENDING MACHINE</button>
            <button  className="category-button"> BAR DECOILING MACHINE</button>
            <button  className="category-button"> BAR BENDING MACHINE </button>
            <button  className="category-button"> BAR CUTTING MACHINE</button>
            <button  className="category-button"> AUTOMATIC STIRRUP BENDING MACHINE</button>
            <button  className="category-button"> PLATE COMPACTOR</button>
            <button  className="category-button"> TAMPING RAMMER</button>
            <button  className="category-button"> VIBRATING ROLLER</button>
            <button  className="category-button"> RIDE ON ROLLER</button>
            <button  className="category-button"> REBAR THREADING MACHINE</button>
            <button  className="category-button"> IRON WORKER/MULTI- CUTTING MACHINE</button>
            <button  className="category-button"> CONCRETE POLISHING &GRINDING MACHINE</button>
            <button  className="category-button"> CONCRETE CUTTING MACHINE</button>
            <button  className="category-button"> POWER TROWEL</button>
            <button  className="category-button"> REBAR STRAIGHTENING MACHINE</button>
            <button  className="category-button"> MINI CRANES</button>
            <button  className="category-button">CONCRETE MIXER WITH HOPPER MACHINE</button>
            <button  className="category-button"> TRUSS SCREED</button>
        </div>
        <div className="beat-grid">
          {beatsData.map((beat) => (
            <BeatCard beat={beat} key={beat.id} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default BeatStore;

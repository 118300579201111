import React, { useState } from 'react';
import '../styles/MixingContactForm.css'; // You can create this CSS file for styling

function MixingContactForm() {
  const [formData, setFormData] = useState({
    mixingFirstName: '',
    mixingLastName: '',
    mixingEmail: '',
    mixingMessage: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission, e.g., sending an email or PayPal invoice
    console.log(formData); // You can replace this with your submission logic
  };

  return (
    <div className="mixing-contact-form">
      <h2>Message me below for a mix!</h2>
      <p>
        In the "message" field, please include which mix type you need and how many beats or songs. I will email you back asap to get the process started. Before mixes begin, I will send you an invoice via PayPal to your email.
      </p>
      <form onSubmit={handleSubmit}>
        <div className="mixing-form-group">
          <input
            type="text"
            name="mixingFirstName"
            placeholder="First Name"
            value={formData.mixingFirstName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mixing-form-group">
          <input
            type="text"
            name="mixingLastName"
            placeholder="Last Name"
            value={formData.mixingLastName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mixing-form-group">
          <input
            type="email"
            name="mixingEmail"
            placeholder="Email Address"
            value={formData.mixingEmail}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mixing-form-group">
          <textarea
            name="mixingMessage"
            placeholder="Message"
            value={formData.mixingMessage}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <div className="mixing-form-group">
          <button type="submit">Send</button>
        </div>
      </form>
    </div>
  );
}

export default MixingContactForm;

import { useParams } from 'react-router-dom';
import beatsData from '../data/beatsData';
import '../styles/IndividualBeat.css';
import React from 'react';

function IndividualBeat() {
  const { beatId } = useParams();
  const beat = beatsData.find((beat) => beat.id === beatId);

  if (!beat) {
    return <div>Product not found.</div>;
  }

  return (
    <div className="individual-beat">
      <h2>{beat.title}</h2>
      <img src={beat.imageUrl} alt={beat.title} />
      <div className="description">
        <h3><center>Description</center></h3>
        <div dangerouslySetInnerHTML={{ __html: beat.description }} />
      </div>
    </div>
  );
}

export default IndividualBeat;

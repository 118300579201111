import React from 'react';
import beatsData from '../data/beatsData'; // Import your beats data
import BeatCard from '../components/BeatCard'; // Import the separate BeatCard component
import './Home.css'; // Import the separate CSS file for Home


function Home() {
  return (

      <div className="home-wrapper">
        <div class="banner">
        </div>
        <div className="beat-grid">
          {beatsData.map((beat) => (
            <BeatCard beat={beat} key={beat.id} />
          ))}
        </div>
      </div>
  );
}

export default Home;

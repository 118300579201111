import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import '../styles/BeatCard.css'; 
function BeatCard({ beat }) {
  return (
    <Link to={`/store/${beat.id}`} className="beat-card">
      <img src={beat.imageUrl} alt={beat.title} />
      <h3>{beat.title}</h3>
    </Link>
  );
}

BeatCard.propTypes = {
  beat: PropTypes.object.isRequired,
};
export default BeatCard;
